import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Layout from '../../components/Layout';
import styles from './security.module.scss';
import SecurityTabs from '../../components/SecurityTabs/SecurityTabs';

export const SecurityPageTemplate = ({
  seo,
  title,
  subtitle,
  features = [],
  hash,
  tabs,
  location,
}) => (
  <Layout seo={seo} location={location}>
    <div className={cx('homepage')}>
      <section className={cx(styles.hero)}>
        <div className={cx('container')}>
          <h1>{ title }</h1>
          { subtitle && <h4 className="lato fw-normal">{subtitle }</h4> }
        </div>
        <div className={cx('container')}>
          <div className={cx('inline-container', 'ic-3', 'ai-top')}>
            { features.map((ft, i) => (  
              <div key={`ft${i}`} className={cx(styles.feature)}>
                { ft.img && <img src={ft.img} alt={ft.title} /> }
                <p><strong>{ ft.title }</strong></p>
                { ft.subtitle && <p>{ft.subtitle }</p> }
              </div>
            ))}
          </div>
        </div>
      </section>
      <SecurityTabs activeHash={hash} tabs={tabs} />
    </div>
    <script type="text/javascript">{`
        piAId = '824443';
        piCId = '1643';
        piHostname = 'pi.pardot.com';
        
        (function() {
          function async_load(){
            var s = document.createElement('script'); s.type = 'text/javascript';
            s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
            var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
          }
          if(window.attachEvent) { window.attachEvent('onload', async_load); }
          else { window.addEventListener('load', async_load, false); }
        })();
      `}
    </script>
  </Layout>
);

SecurityPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

SecurityPageTemplate.defaultProps = {
  subtitle: null,
};

const SecurityPage = (props) => {
  const {
    data: { markdownRemark: { frontmatter }, tabs: tabsData },
    location,
  } = props;
  const tabs = tabsData.edges.map(({ node }) => node);
  return (
    <SecurityPageTemplate
      {...frontmatter}
      {...location}
      tabs={tabs}
    />
  );
};

SecurityPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default SecurityPage;

export const securityQuery = graphql`
  query SecurityQuery {
    markdownRemark(frontmatter: { name: { eq: "security" } }) {
      frontmatter {
        seo {
          title
          description
        }
        title
        subtitle
        features {
          title
          subtitle
          img
        }
      }
    }
    tabs: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___sort] },
      filter: { frontmatter: { name: { eq: "securityTab" } }}
    ) {
      edges {
        node {
          frontmatter {
            title
            hash
          }
          html
        }
      }
    }
  }
`;
